<template>
    <setting-layout
        :title="$t('pages.messages.title')"
        :handle-back-by-emit="true"
        @back="$router.push({ name: 'user.own.profile.view' })"
    >
        <v-container class="pt-0">
            <v-row
                v-if="!user.verifyOndatoAt"
                @click="handleClickTurnOff"
                class="mt-3"
            >
                <v-col cols="1">
                    <v-checkbox
                        hide-details
                        dense
                        class="pt-0 ma-0"
                        color="orange darken-3"
                        v-model="turnOff"
                        :disabled="isDisabledTurnOff"
                        @change="handleTurnOff"
                    />
                </v-col>
                <v-col cols="10">
                    {{ $t("pages.messages.turn_off_direct_messages") }}
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <text-field
                        v-model="search"
                        @keyup="onChangeSearch"
                        :placeholder="$t('pages.messages.search')"
                        class="search-field"
                    >
                        <template v-slot:prepend-inner>
                            <v-icon class="mr-3">mdi-magnify</v-icon>
                        </template>
                    </text-field>
                </v-col>
            </v-row>

            <router-link
                :to="{ name: 'user.own.channels' }"
            >
                <div
                    class="accent-background px-4 py-2 my-2"
                    style="
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        border-radius: 16px;
                    "
                >
                    <div style="display: flex; align-items: center">
                        <icon-signal class="mr-2" />
                        <div>Broadcasts</div>
                    </div>

                    <div style="display: flex; align-items: center">
                        <v-badge
                            v-if="unreadBroadcastsMessagesCount"
                            :content="unreadBroadcastsMessagesCount"
                            :offset-x="24"
                            :offset-y="10"
                            color="#FE4811"
                        >
                        </v-badge>

                        <v-icon size="33">mdi-chevron-right</v-icon>
                    </div>
                </div>
            </router-link>

            <div
                v-for="(item, i) in messages.filter(
                    (chat) => !chat.isBroadcastingChannel
                )"
                :key="i"
                class="message-container"
                v-touch="{
                    right: () => swipeMessage('R', i),
                    left: () => swipeMessage('L', i),
                }"
            >
                <v-row
                    v-if="item.messageCount > 0"
                    align="center"
                    :class="{ deletec: true, delete: deleteItem === i }"
                >
                    <v-col cols="2">
                        <router-link
                            :to="{
                                name: 'otherUser.profile.viewSlug',
                                params: {
                                    slug: item.slug,
                                    from: 'user.own.messages',
                                },
                            }"
                        >
                            <profile-image
                                :src="item.image"
                                :username="item.name"
                            />
                        </router-link>
                    </v-col>
                    <v-col cols="8">
                        <router-link
                            :to="{
                                name: 'user.own.chat',
                                params: { id: item.user_id },
                            }"
                        >
                            <v-row no-gutters>
                                <v-col
                                    style="display: flex; align-items: center"
                                >
                                    <v-badge
                                        v-if="item.unread_messages > 0"
                                        :content="item.unread_messages"
                                        :offset-x="-4"
                                        :offset-y="10"
                                        color="#FE4811"
                                    >
                                        <span class="name-sender font-weight-bold">
                                            {{ item.name }}
                                        </span>
                                    </v-badge>
                                    <span v-else class="name-sender">
                                        {{ item.name }}
                                    </span>
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col>
                                    <span class="text-message d-flex" 
                                        :class="item.unread_messages > 0 ? 'font-weight-bold' : ''" >
                                        <template v-if="item.lastMessageType === 'audio'">
                                            <v-icon size="18">mdi-microphone</v-icon>
                                            {{ $t("messages.type.audio") }}
                                        </template>

                                        <!-- Check if the message type is 'story' -->
                                        <template v-else-if="item.lastMessageType === 'story'">
                                            <v-icon size="18">mdi-image</v-icon>
                                            {{ $t("messages.type.story") }}
                                        </template>

                                        <!-- Check if the message type is 'tip' -->
                                        <template v-else-if="item.lastMessageType === 'user_tip'">
                                            <dollar-icon class="mr-2" />
                                            <span>
                                                {{ item.tipData }} {{ currency }}
                                            </span>
                                        </template>

                                        <!-- Check if the message type is 'item purchase' -->
                                        <template v-else-if="item.lastMessageType === 'creator_tip'">
                                            <dollar-icon class="mr-2" />
                                            <span>
                                                {{ $t('messages.type.creator_tip', { user: item.lastMessageSenderName, item: item.tipData }) }}
                                            </span>
                                        </template>

                                        <!-- Default text for other message types -->
                                        <template v-else>
                                            {{ text(item.text) }}
                                        </template>
                                    </span>
                                </v-col>
                            </v-row>
                        </router-link>
                    </v-col>
                    <v-spacer></v-spacer>
                </v-row>

                <app-transition>
                    <v-row
                        class="delete-icon-container"
                        v-if="deleteItem === i && !item.isBroadcastingChannel"
                        align="center"
                    >
                        <v-spacer />
                        <v-col
                            cols="4"
                            class="delete-icon-background"
                            @click="deleteChat(i, item.id)"
                        >
                            <app-icon
                                icon="delete"
                                class="deleteIcon"
                            ></app-icon>
                            <span class="delete-text d-block text-capitalize">
                                {{ $t("dialogs.delete") }}
                            </span>
                        </v-col>
                    </v-row>
                </app-transition>
            </div>
        </v-container>
        <verify-dialog
            v-if="this.isShowVerifyDialog"
            @close="handleCloseVerifyDialog"
        />
        <change-price-dialog
            v-if="this.isShowChangePriceDialog"
            @close="handleCloseChangePriceDialog"
        />
        <noodzlers-cant-turn-off-messages
            v-if="this.isShowNoodzlersCantTurnOffMessagesDialog"
            @close="handleCloseNoodzlersCantTurnOffMessagesDialog"
        />
    </setting-layout>
</template>

<script>
import IconSignal from "@/assets/images/icons/signal.svg?inline";
import SettingLayout from "@/layouts/app/profile/SettingLayout";
import TextField from "@/components/app/form/TextField";
import VerifyDialog from "@/components/app/dialogs/VerifyDialog";
import ChangePriceDialog from "@/components/app/dialogs/ChangePriceDialog";
import NoodzlersCantTurnOffMessages from "@/components/app/dialogs/NoodzlersCantTurnOffMessages.vue";
import ProfileImage from "@/components/app/notifications/ProfileImage";
import { mapActions, mapMutations, mapState } from "vuex";
import { CURRENCY_SYMBOL } from "@/configs/constants";
import DollarIcon from "@/components/app/common/DollarIcon";

export default {
    name: "Messages",
    data: () => ({
        deleteItem: -1,
        price: "",
        turnOff: false,
        isMounted: false,
        isShowChangePriceDialog: false,
        isShowNoodzlersCantTurnOffMessagesDialog: false,
        currency: CURRENCY_SYMBOL,
        errors: {},
        search: "",
    }),
    watch: {
        price(val) {
            if (val > 0) {
                this.turnOff = false;
                this.setTurnOffMessage({
                    model: {
                        turnOff: this.turnOff,
                    },
                });
            }
        },
    },
    computed: {
        ...mapState({
            isShowVerifyDialog: (state) =>
                state.user.components.isShowVerifyDialog,
            user: (state) => state.user.user.data,
            messages: (state) => state.user.user.messages,
        }),
        isDisabledTurnOff() {
            return this.price > 0;
        },
        unreadBroadcastsMessagesCount() {
            return this.messages
                .filter((chat) => chat.isBroadcastingChannel)
                .reduce((sum, message) => sum + message.unread_messages, 0);
        },
    },
    methods: {
        ...mapMutations(["showSnackBar", "hideVerifyDialog"]),
        handleCloseVerifyDialog() {
            this.hideVerifyDialog();
        },
        handleCloseChangePriceDialog() {
            this.isShowChangePriceDialog = false;
        },
        handleCloseNoodzlersCantTurnOffMessagesDialog() {
            this.isShowNoodzlersCantTurnOffMessagesDialog = false;
        },
        text(text) {
            if (text?.length > 50) return text.substr(0, 50) + "...";
            return text;
        },
        swipeMessage(direction, id) {
            if (direction === "L") this.deleteItem = id;
            else this.deleteItem = -1;
        },
        deleteChat(id, chat_id) {
            this.messages.splice(id, 1);
            this.deleteItem = -1;
            this.deleteMessage(chat_id).then(() => {
                this.refreshData();
            });
        },
        refreshData() {
            this.getCurrentUserData().then((res) => {
                this.price = res.data.directMessagePrice;
                this.turnOff = res.data.directMessageTurnOff;
            });
        },
        async handleTurnOff() {
            this.errors = {};
            if (this.price > 0) {
                this.turnOff = !this.turnOff;
                return;
            }
            const model = {
                turnOff: !!this.turnOff,
            };
            this.setTurnOffMessage({
                model: model,
            })
                .then(() => {
                    this.showSnackBar(this.$t("messages.success"));
                    this.refreshData();
                })
                .catch((e) => {
                    this.errors = e.response.data.errors;
                });
        },
        handleClick() {
            this.isVerifyUser();
        },
        handleClickTurnOff() {
            if (this.user.verifyOndatoAt) {
                this.isShowNoodzlersCantTurnOffMessagesDialog = true;
                return;
            }

            if (this.isDisabledTurnOff) {
                this.isShowChangePriceDialog = true;
            }
        },
        onChangeSearch() {
            this.setMessages(this.search);
        },
        ...mapActions([
            "setMessages",
            "deleteMessage",
            "setTurnOffMessage",
            "getCurrentUserData",
            "isVerifyUser",
        ]),
    },
    async mounted() {
        await this.refreshData();
        await this.setMessages();
        this.isMounted = true;
    },
    components: {
        TextField,
        SettingLayout,
        VerifyDialog,
        ChangePriceDialog,
        ProfileImage,
        NoodzlersCantTurnOffMessages,
        IconSignal,
        DollarIcon,
    },
};
</script>

<style scoped lang="scss">
$image-size: 50px;

.Messages {
    &-price {
        width: 100%;
        max-width: 126px;
    }
}

.profile-image {
    width: $image-size;
    height: $image-size;
    background-position: center;
    background-size: cover;
    border-radius: 50%;
    object-fit: cover;
}

.search-field {
    border-radius: 16px;
}

.text-message {
    color: rgba(255, 255, 255, 0.5);
    line-height: 1.2em;
    font-size: 0.9em;
    margin-top: 5px;
    word-break: break-word;
}

.delete {
    transform: translateX(-63px);
    transition: 0.5s;
}

.deleteIcon {
}

.message-container {
    position: relative;
}

.delete-icon-container {
    position: absolute;
    /* top: 1.2em; */
    width: 100%;
    text-align: center;
    /* background: orange; */
    z-index: 0;
    left: 42px;
    height: 100%;
    top: 0;
    bottom: 0;
}

.delete-icon-background {
    background: #fe4811;
}

.deletec {
    transition: 0.5s;
}
</style>
